import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3 1h12.41L21 6.59V23H3V1zm2 2v18h14V9h-6V3H5zm10 .41V7h3.59L15 3.41zM9 10h4a2 2 0 012 2v1.6a2 2 0 01-2 2h-2V18H9v-8zm2 3.6h2V12h-2v1.6z"}}]};

const FilePowerpoint = Vue.extend<IconProps>({
  name: 'FilePowerpointIcon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'file-powerpoint',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default FilePowerpoint;
